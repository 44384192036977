<template>
  <v-card class="my-4" elevation="4">
    <v-card-title><v-icon class="mr-2">mdi-cog</v-icon> Configurações Gerais</v-card-title>
    <v-card-text class="mx-2 py-0">
      <tooltip-switch
        class-props="mt-1"
        :editable="true"
        :right="true"
        :value.sync="flags.app"
        :label="flags.app ? 'Aplicativo Ativo' : 'Aplicativo Inativo'"
        :tip="appFlagTip"
      />
    </v-card-text>
    <v-card-actions class="pl-0">
      <v-spacer />
      <v-btn color="primary" :disabled="loading" @click="dialog = true"><v-icon class="mr-1">sync</v-icon> Atualizar Flags</v-btn>
    </v-card-actions>

    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="updateFlags" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { MUTATION_UPDATE_APP_FLAGS } from '@/modules/accounts/graphql'

export default {
  components: {
    TooltipSwitch: () => import('@/components/base/TooltipSwitch'),
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    app: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    flags: {
      app: false
    },
    appFlagTip:
      'Controla vários processos internos da Mercafácil que operacionalizam o aplicativo.\nAtivar o switch caso o cliente tenha aplicativo.\nDesativar o switch NÃO remove o App da loja.',
    textDialog: 'Tem certeza que deseja atualizar as flags?'
  }),
  mounted() {
    if (this.app) this.setValues()
  },
  methods: {
    setValues() {
      this.flags.app = this.app?.channels?.app?.active
    },
    closeDialog() {
      this.dialog = false
      this.setValues()
    },
    async updateFlags() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_FLAGS,
          variables: {
            account_id: this.accountId,
            flags: this.flags
          }
        })
        this.$snackbar({ message: 'Flag(s) atualizada(s) com sucesso', snackbarColor: '#2E7D32' })
        this.$emit('refresh')
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar flag(s)', snackbarColor: '#F44336' })
      }
      this.loading = false
    }
  }
}
</script>
